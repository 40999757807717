<template>
  <div class="category-form-container">
    <b-row>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-radio-group
            id="is_show"
            v-model="formData.is_show"
            :options="yesOrNot"
            buttons />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-in_home" label="باش بەتتە كۆرۈنەمدۇ ؟" label-for="in_home">
          <b-form-radio-group
                  id="in_home"
                  v-model="formData.in_home"
                  :options="yesOrNot"
                  buttons />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-8" label="تۈر ماۋزوسى" label-for="title" >
          <b-form-input id="title" v-model="formData.title" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="تۈر ماۋزوسىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="title-live-feedback">
            تۈر ماۋزوسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-other_title" label="ئۇزۇن ماۋزوسى" label-for="other_title" >
          <b-form-input id="other_title" v-model="formData.other_title" max="40" type="text" placeholder="تۈرنىڭ ئۇزۇن ماۋزوسىنى كىرگۈزۈڭ" />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-title-color" label="تۈر رەڭ نۇمۇرى" label-for="title-color">
          <b-input-group>
            <b-form-input id="title-color" placeholder="باشبەتتە كۆرىنىدىغان تۈرنىڭ ئۇزۇن ماۋزوسىغا ئىشلىتىدىغان رەڭ" style="direction: ltr" v-model="formData.title_color" />
            <b-input-group-append>
              <b-button class="d-flex align-items-center justify-content-center">
                <colorPicker ref="colorPicker" v-model="formData.title_color || ''" @change="changeTitleColor" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-9" label="تۈر تەرتىبى" label-for="sort" >
          <b-form-input style="width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="تۈرنىڭ تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="3">
        <b-form-group :description="description" aria-describedby="target-live-feedback" id="input-group-navigation_image" :label="`ئايكون رەسىم`" label-for="icon_image">
          <image-uploader v-model="formData.icon_image" :upload-form="{strategy: 'film_category'}" />
          <b-form-invalid-feedback id="target-live-feedback">
            تۈر ئۈچۈن ئايكون رەسىم تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import "@c@/plugins/vue-color-picker";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import categoryMixin from "@m@/category.mixin";

  export default {
    name: "CategoryForm",
    mixins: [ categoryMixin, formBusyMixin, validateStateMixin, validationMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: "create"
      },
      modelId: {
        type: [String, Number],
      },
    },
    validations: {
      formData: {
        title:          { required }
      }
    },
    components: { ImageUploader },
    computed: {},
    created() {
      if (this.action === 'edit'){
        this.getCategory(this.modelId)
          .then(({ data: category })=>{
            this.formData = category;
          })
      }
    },
    data(){
      return {
        yesOrNot: [
          { text: 'شۇنداق', value: true },
          { text: 'ياق', value: false },
        ],
        formData: {
          title: '',
          other_title: '',
          title_color: '',
          icon_image: '',
          in_home: false,
          is_show: true,
          sort: null
        },
        description: `كەڭلىك ئېگىزلىك 1:1 نىسبەتتىكى رەسىم يوللاشنى تەۋسىيە قىلىمىز. يەنى ئېگىزلىك ۋە كەڭلىك ئوخشاش چوڭلۇقتا بولغان رەسىمنى يوللاڭ، رازمېرى 100 پېكسىل بولسا بىر قەدەر مۇۋاپىق`
      };
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning({
            message: 'تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ'
          });
          return;
        }
        if (this.action === 'create'){
          this.storeCategory(this.formData);
        }else if ( this.action === 'edit' ){
          this.updateCategory(this.formData);
        }
      },
      changeTitleColor(e){
        this.formData.title_color = e;
      }
    }
  }
</script>
